<template>
  <div class="req-temp-container" v-if="isSetupComplete">
    <vs-row class="items-start">
      <vs-col vs-type="flex" vs-w="12">
        <vs-card class="custom-vx-card">
          <vs-table class="webhook-table" :data="webhookList">
            <template slot="header">
              <vs-select
                class="mb-5 flex force-width w-full items-center"
                label="Show records"
                v-model="limit"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in limitValue"
                />
              </vs-select>
            </template>
            <template class="tableHeader" slot="thead">
              <vs-th style="width: 17%"> Onboard ID </vs-th>
              <vs-th> Payload </vs-th>
              <vs-th> Status </vs-th>
              <vs-th> Action </vs-th>
            </template>

            <template aria-valuemax="" slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td style="width: 17%" :data="tr.onboardingId">
                  {{ tr.onboardingId }}
                </vs-td>

                <vs-td :data="tr.payload">
                  {{ tr.payload }}
                </vs-td>

                <vs-td :data="tr.status">
                  <span
                    style="white-space: pre"
                    :class="formattedStatusClass(tr.status)"
                  >
                    {{ tr.status }}
                  </span>
                </vs-td>

                <vs-td :data="tr">
                  <a
                    v-if="tr.status.toLowerCase() === 'no match'"
                    href="#"
                    class="underline cursor-pointer"
                    @click="resolveNoMatch(tr._id)"
                    >Resolve
                  </a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="mt-3">
            <v-pagination
              v-model="pageNumber"
              :page-count="totalPage"
            ></v-pagination>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import vPagination from "@/views/components/pagination";

export default {
  components: { vPagination },

  name: "index",

  data() {
    return {
      isSetupComplete: false,
      limitValue: [
        { text: "1", value: 1 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      webhookList: [],
    };
  },

  mounted() {
    this.getListSetting();
    this.checkAdminLogin();
  },

  methods: {
    ...mapActions("onboardingAdmin", [
      "fetchTillWebhookList",
      "resolveNoMatchIssue",
    ]),
    ...mapActions("admin", ["checkLogin"]),

    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };

      await this.$store
        .dispatch("listSetting/fetchListSettingByPage", payload)
        .then((res) => {
          this.limit = parseInt(res.data.data.limit) || this.limit;
          this.isSetupComplete = true;
          this.getTillWebhookList(this.pageNumber, this.limit);
        })
        .catch((err) => {
          
          this.isSetupComplete = true;
          this.getTillWebhookList(this.pageNumber, this.limit);
        });
    },

    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store
        .dispatch("listSetting/updatelistSetting", payload)
        .then((res) => {})
        .catch((err) => {
          
        });
    },

    moment(date) {
      return moment(date).format("DD MMM YYYY");
    },

    async getTillWebhookList(page, limit) {
      this.$vs.loading();
      const params = `?page=${page}&limit=${limit}&sort=desc`;
      await this.fetchTillWebhookList(params)
        .then((result) => {
          this.webhookList = result.data.data;
          this.totalData = result.data.pagination.pagination.total
            ? result.data.pagination.pagination.total
            : 0;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Notification",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",

          });
        });
    },

    formattedStatusClass(status) {
      let bgClass = "";
      switch (status.toLowerCase()) {
        case "match":
          bgClass = "badge success-border";
          break;

        case "no match":
        case "manually provisioned":
          bgClass = "badge danger-border";
          break;

        case "no match - resolved":
          bgClass = "badge success-border";
          break;

        case "manual entry - resolved":
        case "manually provisioned - resolved":
          bgClass = "badge success-border";
          break;
      }

      return bgClass;
    },

    resolveNoMatch(id) {
      this.$vs.loading();
      const payload = { webhookId: id };
      this.resolveNoMatchIssue(payload)
        .then(async () => {
          await this.getTillWebhookList(this.pageNumber, this.limit);
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Notification",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",

          });
        });
    },
    async checkAdminLogin() {
      await this.checkLogin();
    }
  },

  computed: {
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },

  },

  watch: {
    pageNumber(val) {
      this.getTillWebhookList(val, this.limit);
    },
    limit(val, old) {
      this.pageNumber = 1;
      if (typeof old === "undefined" || old === val) {
      } else {
        this.getTillWebhookList(this.pageNumber, val);
        this.saveListSetting(val);
      }
    },
  },
};
</script>